import React from "react"
import PropTypes from "prop-types"

const BusinessLocation = ({ placeId }) => {
  return (
    <iframe
      title="Business Location"
      className="border-0 w-full h-96"
      width="100%"
      height="100%"
      loading="lazy"
      allowFullScreen
      src={`https://www.google.com/maps/embed/v1/place?key=${process.env.GATSBY_G_MAPS_KEY}&q=place_id:${placeId}`}
    ></iframe>
  )
}

BusinessLocation.propTypes = {
  placeId: PropTypes.node.isRequired,
}

export default BusinessLocation

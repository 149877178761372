import React from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import SEO from "../components/seo"
import Hero from "../components/hero"
import BusinessLocation from "../components/business-location"
import { StaticImage } from "gatsby-plugin-image"

const IndexPage = () => {
  const { title } = useSiteMetadata()
  const logoClassNames = "w-8/12"
  return (
    <>
      <SEO title="Home" />
      <Hero />
      <div className="p-8 xl:px-80">
        <article className="max-w-full prose dark:prose-invert prose-headings:text-brand-primary prose-headings:dark:text-brand-secondary">
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-8">
            <div>
              <h1>Welcome to {title}</h1>
              <p>
                We are pleased to have the opportunity to provide orthodontics
                for you and your family. Our goal is to deliver exceptional
                orthodontic treatment in a caring, state-of-the-art environment.
                Along with attention to each and every detail, we are dedicated
                to treating patients with sensitivity, in a relaxed yet
                professional manner. We want your visits to our office to be
                enjoyable. Patient care is enhanced by our continued involvement
                in orthodontic research, education, and study. We believe it is
                important that the professional remains a perpetual learner to
                provide the best treatment for our patients.
              </p>
            </div>
            <div>
              <h1>Office hours</h1>
              <table className="table-auto mb-0">
                <thead>
                  <tr>
                    <th>Day</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Tuesday, Wednesday, Friday</td>
                    <td>10:00 AM to 7:00 PM</td>
                  </tr>
                  <tr>
                    <td>Saturday</td>
                    <td>9:30 AM to 5:30 PM</td>
                  </tr>
                  <tr>
                    <td>Monday, Thursday, Sunday</td>
                    <td>Closed</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </article>
      </div>
      <div className="w-full bg-zinc-100 dark:bg-zinc-900">
        <div className="p-8 xl:px-80">
          <article className="max-w-full prose dark:prose-invert prose-headings:text-brand-primary prose-headings:dark:text-brand-secondary prose-h1:mb-2 prose-h2:mt-4">
            <h1>Our services</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-8">
              <div>
                <h2>Orthodontics</h2>
                <ul>
                  <li>Traditional metal braces</li>
                  <li>Ceramic clear braces</li>
                  <li>Clear aligners (Invisalign, SureSmile)</li>
                  <li>Interceptive orthodontics (for children)</li>
                  <li>Impacted teeth and wisdom teeth</li>
                  <li>Coordinated orthognathic jaw surgery</li>
                  <li>Accelerated orthodontics</li>
                  <li>TMJ disorder (TMD)</li>
                  <li>Trauma</li>
                </ul>
              </div>
              <div>
                <h2>Removable and fixed appliances</h2>
                <ul>
                  <li>Clear essix retainer</li>
                  <li>Hawley retainer</li>
                  <li>Fixed retainer</li>
                  <li>Nightguard</li>
                  <li>Mouthguard</li>
                  <li>Space maintainer</li>
                  <li>Carriere motion appliance</li>
                  <li>Orthodontic headgear/face frame</li>
                </ul>
              </div>
              <div>
                <h2>Tooth whitening</h2>
                <ul>
                  <li>Zoom! Professional in-office whitening</li>
                  <li>At-home whitening kits</li>
                </ul>
              </div>
              <div>
                <h2>Imaging</h2>
                <ul>
                  <li>3-D intraoral scan</li>
                  <li>Panoramic x-ray</li>
                  <li>Periapical x-ray</li>
                  <li>Occlusal x-ray</li>
                  <li>Lateral cephalogram</li>
                </ul>
              </div>
            </div>
          </article>
        </div>
      </div>
      <div className="w-full">
        <BusinessLocation placeId="ChIJk7QJ47fS1IkRecpmvZaSns0" />
      </div>
      <div className="dark:bg-zinc-900 p-8 xl:px-80">
        <div className="grid grid-cols-2 md:grid-cols-3 justify-items-center gap-y-4">
          <StaticImage
            className={logoClassNames}
            placeholder="TRACED_SVG"
            src="../images/logos/acceledent.png"
            transformOptions={{ grayscale: true }}
            objectFit="contain"
            alt="Logo of Acceledent"
          ></StaticImage>
          <StaticImage
            className={logoClassNames}
            placeholder="TRACED_SVG"
            src="../images/logos/invisalign.png"
            transformOptions={{ grayscale: true }}
            objectFit="contain"
            alt="Logo of Invisalign"
          ></StaticImage>
          <StaticImage
            className={logoClassNames}
            placeholder="TRACED_SVG"
            src="../images/logos/suresmile.png"
            transformOptions={{ grayscale: true }}
            objectFit="contain"
            alt="Logo of SureSmile"
          ></StaticImage>
          <StaticImage
            className={logoClassNames}
            placeholder="TRACED_SVG"
            src="../images/logos/aao.png"
            transformOptions={{ grayscale: true }}
            objectFit="contain"
            alt="Logo of American Association of Orthodontists"
          ></StaticImage>
          <StaticImage
            className={logoClassNames}
            placeholder="TRACED_SVG"
            src="../images/logos/oao.png"
            transformOptions={{ grayscale: true }}
            objectFit="contain"
            alt="Logo of Ontario Association of Orthodontists"
          ></StaticImage>
          <StaticImage
            className={logoClassNames}
            placeholder="TRACED_SVG"
            src="../images/logos/oda.png"
            transformOptions={{ grayscale: true }}
            objectFit="contain"
            alt="Logo of Ontario Dental Association"
          ></StaticImage>
        </div>
      </div>
    </>
  )
}

export default IndexPage

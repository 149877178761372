import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { ContactLink } from "./links"

const Hero = ({ data }) => {
  const { heroImage } = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 2000
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)

  return (
    <div
      className="hero min-h-screen min-w-full"
      style={{
        backgroundImage: `url(${getImage(heroImage).images.fallback.src})`,
      }}
    >
      <div className="hero-overlay bg-opacity-45"></div>
      <div className="text-center hero-content text-neutral-content">
        <div className="max-w-md">
          <h1 className="mb-5 text-4xl font-bold">
            The safety and health of our patients is our top priority.
          </h1>
          <p className="mb-5 text-2xl">
            Call to find out how we can accommodate you into our schedule.
          </p>
          <ContactLink
            title="Contact Us Today"
            className="btn glass bg-brand-primary hover:bg-brand-secondary text-xl"
          />
        </div>
      </div>
    </div>
  )
}

export default Hero
